import React from "react";
import { Container } from 'react-bootstrap';
import styled from 'styled-components';

const Styles = styled.div`
   .jumbo {
      background: rgb(240,242,242) no-repeat fixed bottom;
      background: radial-gradient(circle, rgba(240,242,242,1) 0%, rgba(199,204,203,1) 92%, rgba(184,191,191,1) 100%);
      background-size: cover;
      color: red;
      height: 150px;
      position: relative;
      z-index: 0;
   }
   .overlay {
      background-color: black;
      opacity: 0.7;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right:0;
      z-index: -1;
   }
`

const RentBanner = () => {
   return (
      <Styles>
         <Container style={{ 'backgroundColor': 'lightgrey', color: 'red', padding: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div className="jumbo2 " >
               <Container>
                  <h3>СПЕЦТЕХНИКА В АРЕНДУ</h3>
               </Container>
            </div>
         </Container>
         <br/>
      </Styles>
   )
}

export default RentBanner;