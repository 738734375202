import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import main from '../img/sls/main.jpg';
import { Slide } from "react-reveal";


const Info = () => {
   return (
      <>
         <Container >
            <Row>
               <Col md={4}>
                  <h1> НАШИ <span style={{color: '#de3a1f'}}>ПРЕИМУЩЕСТВА:</span>  </h1>
                  <ul>
                     <li>СОВРЕМЕННЫЕ ТЕХНОЛОГИИ</li>
                     <li>НИЗКИЕ ЦЕНЫ</li>
                     <li>МОЩНОЕ ОБОРУДОВАНИЕ</li>
                     <li>ПРОФЕССИОНАЛЬНАЯ КОМАНДА</li>
                     <li>КАЧЕСТВЕННЫЕ МАТЕРИАЛЫ</li>
                  </ul>

                  
                  </Col>
               <Col md={8} >
                  <Slide left>
                  <Container style={{display: 'flex', justifyContent: 'end', padding: '30px'}}>
                     <img src={main} width={350} />
                  </Container>
                  </Slide>
                  
               </Col>
               
            </Row>
         </Container>
      </>
   )
}

export default Info;