import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import con1 from '../img/cons/con1.jpg';
import con3 from '../img/cons/con3.jpg';
import con5 from '../img/cons/con5.jpg';

import { Fade } from "react-reveal";



const ConInfo = () =>  {
      return (
         <>
            <Container style={{ 'backgroundColor': '#e5e5e5', verticalAlign: 'middle'}}>
               <Row >
                  <Col md={8} style={{ 'backgroundColor': 'lightgrey', color: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: '600'}}>
                  <Container>
                        <Fade left>
                           <h2 >ПРОИЗВОДСТВО БЕТОНА</h2>
                           <p>
                              <span style={{ 'color': '#de3a1f' }}>ТОКМОК</span> БЕТОН производит товарный бетон и цементо-песчаные смеси различных видов и марок.
                           </p>
                        </Fade>
                     </Container>
                  </Col>
                  <Col md={4} >
                     <img src={con1} height={350} />
                  </Col>
               </Row>
                  <br />
                  
   
               <Row>   
                  <Col md={4} >
                     <img src={con3} height={350} />
                  </Col>
                     
                  <Col md={8} style={{ 'backgroundColor': 'grey', color: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: '600'}}>
                     <Container>
                        <Fade right>
                           <h2>СРОК ДОСТАВКИ</h2>
                           <p>
                              С <span style={{'color': '#de3a1f'}}>ТОКМОК</span> БЕТОН заказчики могут рассчитывать на оперативную доставку в любую точку города, а так же по Чуйскому и Кеминскому районам.
                           </p>
                        </Fade>
                     </Container>
                  </Col>
               </Row>
   
               <br />
               <Row >
                  <Col md={8} style={{ 'backgroundColor': 'lightgrey', color: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: '600'}}>
                     <Container >
                        <Fade left>
                           <h2 >ДОСТАВКА БЕТОНА</h2>
                           <p>
                              Доставка приобретенного бетона осуществляется автотранспортом. Наше предприятие имеет свой собственный автопарк для удобства доставки товарного бетона.
                           </p>
                        </Fade>
                     </Container>
                  </Col>
                  <Col md={4} >
                     <img src={con5} height={350} />
                  </Col>
               </Row>  
   
            </Container>
         </>
      )
}

export default ConInfo;