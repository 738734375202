
import React from "react";
import { Carousel, Container } from "react-bootstrap";
import sl1 from '../img/sls/sl1.jpg';
import sl2 from '../img/sls/sl2.jpg';
import sl3 from '../img/sls/sl3.jpg';
import eq9 from '../img/sls/eq9.jpg';

const Slider = () => {
   return (
      <>
         <Container style={{ 'backgroundColor': '#e5e5e5'}}>
            <Carousel>
               <Carousel.Item style={{'height':'100%'}}>
                  <img
                     className="d-block w-100"
                     src={sl2}
                     alt="Токмок Бетон"
                  />
               </Carousel.Item>
               
               <Carousel.Item style={{'height':'100%'}}>
                  <img
                     className="d-block w-100"
                     src={sl3}
                     alt="Токмок Бетон"
                  />
               </Carousel.Item>

               <Carousel.Item style={{'height':'100%', }}>
                  <img
                     className="d-block w-100"
                     src={sl1}
                     alt="Токмок Бетон"
                  />
               </Carousel.Item>

               <Carousel.Item style={{'height':'100%'}}>
                  <img
                     className="d-block w-100"
                     src={eq9}
                     alt="Токмок Бетон"
                  />
               </Carousel.Item>

            </Carousel>
         </Container>
      </>
   )
}

export default Slider