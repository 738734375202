import React from "react";
import { Helmet } from 'react-helmet';
import ConInfo from "../components/ConInfo";

export const Concrete = () => {
   return (
      <>
         <Helmet>
            <meta
               name="description"
               content="Tokmok concrete page"
            />
            <title>Бетон</title>
         </Helmet>  
         <ConInfo />
      </>
   )
}