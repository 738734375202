import React from "react";
import { Container } from "react-bootstrap";

const Footer = () => {
   return (
      <>
         <Container fluid style={{ 'backgroundColor': '#212529', color: '#ffffff'}}>
            <Container style={{display: 'flex', justifyContent: 'center', padding: '10px'}}>
               <h1 >©<span style={{ color: '#de3a1f' }}>ТОКМОК</span> БЕТОН </h1>
            </Container>
         </Container>
      </>
   )
}

export default Footer;