import React from "react";
import { Helmet } from 'react-helmet';

import ConInfo from "../components/ConInfo";
import Slider from '../components/Slider'
import Bunner from "../components/Banner";
import EquipmentInfo from "../components/EqInfo";
import Info from "../components/AboutUs";
import Address from "../components/Address"
import Banner2 from "../components/Banner2";
import RentBanner from "../components/RentBanner";

export const Home = () => {
   return (
      <>
         <Helmet>
            <meta
               name="description"
               content="Tokmok concrete main page"
            />
            <title>Токмок Бетон</title>
         </Helmet>   
            <Info />
            <Bunner/>
            <ConInfo /><br/>
            <Slider />
            <RentBanner />
            <EquipmentInfo />
            <Address />

      </>
   )
}