import React from "react";
import { Helmet } from 'react-helmet';

import EquipmentInfo from "../components/EqInfo";

export const Equipment = () => {
   return (
      <>
         <Helmet>
            <meta
               name="description"
               content="Tokmok concrete equipment"
            />
            <title>Спецтехника в аренду</title>
         </Helmet>   
         <EquipmentInfo />
      </>
   )
}