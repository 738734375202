import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import React from 'react';
import Navibar from './components/Navibar';
import Footer from './components/Footer';

import { Home } from './pages/home'
import { Concrete } from './pages/concretePage'
import { Equipment } from './pages/equipmentPage'
import { AdressPage } from './pages/adressPage';
import { Admin } from './pages/adminPage'

import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <>
      <Router>
        <Navibar />
        <Routes>
          <Route exect path="/" element={<Home/>}/>
          <Route path="/concretePage" element={<Concrete/>}/>
          <Route path="/equipmentPage" element={<Equipment/>}/>
          <Route path="/adressPage" element={<AdressPage />} />
          <Route path="/adminPage" element={<Admin/>}/>
        </Routes>
        <Footer/>
      </Router>
    </>
  )
}

export default App;
