
import React from "react";
import { Nav, Navbar, Button, Container } from "react-bootstrap";
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Styles = styled.div`
   a, span, .navbar-brand, .navbar-nav .nav-link {
      color: black;
      height: 30px;
      font-weight: bold;
      margin-bottom: 0 auto;
      &: hover {
         color: grey
      }
   }
`

const Navibar = () => {
   return (
      <>
         <Styles>
            <Navbar collapseOnSelect expand="lg" bg="light" variant="light"  >
               <Container style={{display: 'flex', justifyContent: 'center', padding: '30px'}}>
                  <Navbar.Brand><Link style={{'text-decoration': 'none'}} to="/"><span style={{'color': '#de3a1f'}}>ТОКМОК</span> БЕТОН</Link></Navbar.Brand>
                  <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                  <Navbar.Collapse id="responsive-navbar-nav" style={{'justifyContent': 'right'}}>
                     <Nav className="faded mr-auto">
                        <Nav.Link><Link  to="/">Главная страница</Link></Nav.Link>
                        <Nav.Link><Link to="/concretePage">Бетон</Link></Nav.Link>
                        <Nav.Link><Link to="/equipmentPage">Аренда спецтехники</Link></Nav.Link>
                        <Nav.Link><Link to="/adressPage">Контакты</Link></Nav.Link>
                     </Nav>
                  </Navbar.Collapse>
               </Container>
            </Navbar>
         </Styles>
      </>
   )
}



export default Navibar