import React from "react";
import { Container, Row, Col } from 'react-bootstrap';

const Address = () => {
   return (
      <>
         <Container fluid style={{ backgroundColor: '#212529', color: '#ffffff'}} >
            <Row >
               <Col md={4}style={{display: 'flex', alignItems: 'row', justifyContent: 'center'}} >
                  

               </Col>
               <Col md={8} style={{ fontWeight: '600' }}>
                  <br/>
                  <p>Контакты:</p>

                  <p> Наш адрес: г. Токмок, Восточная промзона</p>
                  <p >email: oaousm4@gmail.com </p>
                  <p >тел. : +996 (552) 21 86 51; +996 (701) 66 66 03</p>
                  <a href="https://www.instagram.com/tokmok_beton/">@tokmok_beton</a>
               </Col>
            </Row>

            <Row >
               
                  <div className="map">
                     <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2927.6164343653904!2d75.32328131547018!3d42.79645797915999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x72ea49adf0b760cb!2zNDLCsDQ3JzQ3LjMiTiA3NcKwMTknMzEuNyJF!5e0!3m2!1sru!2skg!4v1647868758896!5m2!1sru!2skg" >

                     </iframe>
                  </div>
               
            </Row>
         </Container>
      </>
   )
}

export default Address;