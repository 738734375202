import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import eq1 from '../img/eqs/eq1.jpg';
import eq2 from '../img/eqs/eq2.jpg';
import eq31 from '../img/eqs/eq31.jpg';



import { Fade } from "react-reveal";
import RentBanner from "./RentBanner";

const EquipmentInfo = () => {
   return (
      <>
         <Container style={{ 'backgroundColor': '#e5e5e5', verticalAlign: 'middle'}}>
            <Row>   
               <Col md={4} >
                  <img src={eq1} height={350} />
               </Col>
                  
               <Col md={8} style={{ 'backgroundColor': 'grey', color: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: '600'}}>
                  <Container>
                     <Fade bottom>
                        <h2>АВТОГРЕЙДЕР</h2>
                     </Fade>
                  </Container>
               </Col>
            </Row>

            <br />
            <Row >
               <Col md={8} style={{ 'backgroundColor': 'lightgrey', color: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: '600'}}>
               <Container>
                     <Fade bottom>
                        <h2>ЭКСКАВАТОР</h2>
                     </Fade>
                  </Container>
               </Col>
               <Col md={4} >
                  <img src={eq2} height={350} />
               </Col>
            </Row>  
            <br />
            
            <Row>   
               <Col md={4} >
                  <img src={eq31} height={350} />
               </Col>
                  
               <Col md={8} style={{ 'backgroundColor': 'grey', color: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: '600'}}>
                  <Container>
                     <Fade bottom>
                        <h2>БУЛЬДОЗЕР</h2>
                     </Fade>
                  </Container>
               </Col>
            </Row>
         </Container>
      </>
   )
}

export default EquipmentInfo;