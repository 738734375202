import React from "react";
import { Helmet } from 'react-helmet';

import Adress from "../components/Address";

export const AdressPage = () => {
   return (
      <>
         <Helmet>
            <meta
               name="description"
               content="Tokmok concrete address page"
            />
            <title>Контакты</title>
         </Helmet> 
         <Adress/>
      </>
   )
}