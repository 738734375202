import React from "react";
import eq3 from '../img/eqs/eq3.jpg';
import styled from 'styled-components';

const Styles = styled.div`
   .jumbo {
      background: url(${eq3}) no-repeat fixed bottom;
      background-size: cover;
      color: black;
      font-weight: '700';
      height: 150px;
      position: relative;
      z-index: -1;
   }
   .overlay {
      background-color: black;
      opacity: 1;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right:0;
      z-index: -1;
   }
`

const Banner2 = () => {
   return (
      <Styles>
         <div className="jumbo mr-2" style={{ 'backgroundColor': 'lightgrey', padding: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            
         </div>
      </Styles>
   )
}

export default Banner2;